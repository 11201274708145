import React from 'react';
import CouponCard from './Coupon';
import { useGetBuyerCouponQuery } from '../../../Redux/API/ApiSlice';
import { getUserDetails } from '../../../Helper/SessionHelper';

const CouponList = () => {
    const user = JSON.parse(localStorage.getItem("UserDetails"));

    console.log(user._id);

    
    
    const {data,isSuccess,isLoading}=useGetBuyerCouponQuery( getUserDetails()?._id)
    const coupon = {
        code: 'FOODI',
        description: 'Get 150tk off on your next two orders above 349tk (New User Only).',
      };
      console.log(data);
      
  return (
    <div className="container d-flex justify-content-center mt-5">
        {
            isSuccess && data.data.map(item=><CouponCard data={item} />)
        }
       {/* <CouponCard code={coupon.code} description={coupon.description} /> */}
    </div>
  );
};

export default CouponList;
