import React, { useState } from 'react';
import { Modal, Button, Form, Card, Row, Col, Badge } from 'react-bootstrap';
import { useGetBuyerOrderQuery } from '../../../Redux/API/ApiSlice';
import { getUserDetails } from '../../../Helper/SessionHelper';
import { FaStar, FaRegStar, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { BaseURL } from '../../../Helper/config';
import { ImageUploader } from '../../../ImageUploader/ImageUploader';
import { setLoading } from '../../../Redux/feature/ImageLoader/ImageLoaderSlice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import './Review.css'; // Custom CSS for responsiveness

const Review = () => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]); // Separate for images
  const [selectedVideos, setSelectedVideos] = useState([]); // Separate for videos
  const [reviewForm, setReviewForm] = useState({
    rating: 0,
    comment: '',
    isAnonymous: false,
  });

  const { data, isLoading, isSuccess } = useGetBuyerOrderQuery({
    id: getUserDetails()?._id,
    orderStatus: "Delivary Done",
    page: 1,
    limit: 1000,
  });

  const handleReviewClick = (product, sellerID, orderID) => {
    setSelectedProduct({ ...product, sellerID, orderID });
    setShowReviewModal(true);
  };

  const handleRatingChange = (rating) => {
    setReviewForm((prev) => ({ ...prev, rating }));
  };

  const handleFileChange = (event) => {
    const { files, accept } = event.target;
    const fileList = Array.from(files);

    if (accept === "image/*") {
      setSelectedImages((prevImages) => [...prevImages, ...fileList]);
    } else if (accept === "video/*") {
      setSelectedVideos((prevVideos) => [...prevVideos, ...fileList]);
    }
  };

  const handleDrop = (event, type) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);

    if (type === "image") {
      setSelectedImages((prevImages) => [...prevImages, ...files]);
    } else if (type === "video") {
      setSelectedVideos((prevVideos) => [...prevVideos, ...files]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveVideo = (index) => {
    setSelectedVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  const dispatch = useDispatch();

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    let reviewData = {
      orderID: selectedProduct.orderID,
      sellerID: selectedProduct.sellerID,
      customerID: getUserDetails()?._id,
      foodID: selectedProduct._id,
      reviewStar: reviewForm.rating,
      reviewDetails: reviewForm.comment,
      isAnonymous: reviewForm.isAnonymous,
    };

    const imagePromise = ImageUploader(
      getUserDetails(),
      "Review/",
      selectedImages
    ).then((data) => {
      if (data.status === "Success") {
        reviewData['reviewImage'] = data.images;
        console.log(data);
        dispatch(setLoading(false));
      }
    });

    try {
      const response = await axios.post(`${BaseURL}/create-food-review`, reviewData);
      console.log('Review submitted successfully:', response.data);

      Swal.fire({
        icon: "success",
        title: "Review Done",
        showConfirmButton: false,
        timer: 1500,
      });
      setShowReviewModal(false);
      setReviewForm({ rating: 0, comment: '', isAnonymous: false });
      setSelectedImages([]);
      setSelectedVideos([]);
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  const StarRating = ({ rating, onRatingChange, interactive = false }) => {
    return (
      <div className="d-flex align-items-center">
        {[1, 2, 3].map((star) => (
          <span
            key={star}
            onClick={() => interactive && onRatingChange(star)}
            style={{ cursor: interactive ? 'pointer' : 'default', fontSize: '1.5em' }}
          >
            {star <= rating ? <FaStar className="text-warning" /> : <FaRegStar className="text-warning" />}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="container py-4 review-container">
      <h1 className="mb-4">My Orders & Reviews</h1>
      {isSuccess && data?.data?.map((order) => (
        <Card key={order._id} className="mb-4 shadow-sm">
          <Card.Header className="bg-light">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">{order?.seller_INFO?.[0]?.kitchenName || ""}</h5>
              <Badge bg={order?.orderStatus === "Delivery Done" ? 'success' : 'warning'}>
                {order?.status}
              </Badge>
            </div>
            <small className="text-muted">Order Date: {order.orderDate}</small>
          </Card.Header>

          <Card.Body>
            <Row className="mb-3 d-none d-md-flex">
              <Col><strong>Image</strong></Col>
              <Col><strong>Items</strong></Col>
              <Col xs={2}><strong>Quantity</strong></Col>
              <Col xs={2}><strong>Price</strong></Col>
              <Col xs={3}><strong>Review</strong></Col>
            </Row>

            {order.orderItems.map((item) => (
              <Row key={item._id} className="py-2 border-bottom align-items-center order-item-row">
                <Col xs={12} md={2}>
                  <img src={item?.foodImage?.[0]?.small?.imageUrl || ''} alt="Food" className="img-fluid" />
                </Col>
                <Col xs={6} md>{item.foodName}</Col>
                <Col xs={3} md={2}>{item.foodQty}</Col>
                <Col xs={3} md={2}>tk{(item.foodPrice * item.foodQty).toFixed(2)}</Col>
                <Col xs={12} md={3} className="mt-2 mt-md-0 text-center">
                  {item.reviewed ? (
                    <Badge bg="secondary">Reviewed</Badge>
                  ) : (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleReviewClick(item, order.seller_INFO[0]?._id, order._id)}
                    >
                      Write Review
                    </Button>
                  )}
                </Col>
              </Row>
            ))}

            <div className="mt-3 text-end">
              <strong>Total: tk{order?.orderItems?.reduce((sum, item) => sum + (item.foodPrice * item.foodQty), 0).toFixed(2)}</strong>
            </div>
          </Card.Body>
        </Card>
      ))}

      <Modal show={showReviewModal} onHide={() => setShowReviewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Review {selectedProduct?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitReview}>
            <Form.Group className="mb-3">
              <Form.Label>Rating</Form.Label>
              <StarRating rating={reviewForm.rating} onRatingChange={handleRatingChange} interactive />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Your Review</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reviewForm.comment}
                onChange={(e) => setReviewForm((prev) => ({ ...prev, comment: e.target.value }))}
                placeholder="Share your experience with this product..."
                required
              />
            </Form.Group>

            {/* Image Upload */}
            <Form.Group className="mb-3">
              <Form.Label>Upload Images</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
              />
              <div className="d-flex flex-wrap mt-2">
                {selectedImages.map((image, index) => (
                  <div key={index} className="position-relative m-2">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Selected"
                      className="img-thumbnail"
                      style={{ width: "80px", height: "80px", objectFit: "cover" }}
                    />
                    <FaTimes
                      className="position-absolute top-0 end-0 text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveImage(index)}
                    />
                  </div>
                ))}
              </div>
            </Form.Group>

            {/* Video Upload */}
            <Form.Group className="mb-3">
              <Form.Label>Upload Videos</Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                multiple
                onChange={handleFileChange}
              />
              <div className="d-flex flex-wrap mt-2">
                {selectedVideos.map((video, index) => (
                  <div key={index} className="position-relative m-2">
                    <video
                      src={URL.createObjectURL(video)}
                      controls
                      className="img-thumbnail"
                      style={{ width: "80px", height: "80px", objectFit: "cover" }}
                    />
                    <FaTimes
                      className="position-absolute top-0 end-0 text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveVideo(index)}
                    />
                  </div>
                ))}
              </div>
            </Form.Group>

            <Form.Group className="mb-3 form-check">
              <Form.Check
                type="checkbox"
                label="Submit review anonymously"
                checked={reviewForm.isAnonymous}
                onChange={(e) => setReviewForm((prev) => ({ ...prev, isAnonymous: e.target.checked }))}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Submit Review
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Review;
