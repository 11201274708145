import React from 'react';
import { FaTicketAlt, FaCoins } from 'react-icons/fa'; // Include FaCoins
import './CouponCard.css';

const CouponCard = ({ data }) => {
    const {couponCode,couponAmount}=data
    
  return (
    <div className="coupon-card border rounded p-3 d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <FaTicketAlt className="coupon-icon me-2" />
        <div>
          <h5 className="m-0">
            <span className="text-danger">Use Promocode </span>
            <span className="coupon-code">{couponCode}</span>
          </h5>
          <p className="text-muted small">use this coupon and got {couponAmount}tk for every food</p>
        </div>
      </div>
      <div className="coupon-coin">
        <FaCoins className="coin-icon" /> {/* Coin icon */}
      </div>
    </div>
  );
};

export default CouponCard;
