import React, { useContext, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { Button } from "react-bootstrap";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import Swal from "sweetalert2";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/recipe.json"

const FoodDairy = () => {
  const [EditorData, setEditorData] = useState();
  const [data, setData] = useState({
    title: "",
    seoMetaTitle: "",
    seoMetaDescription: "",
    seoMetaKeywords: "",
  });

  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const [disable, setdisable] = useState(true);
  const navigate = useNavigate();
  console.log(typeof currentFruit);
  const [selectedMetaImage, setSelectedMetaImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const handleChange = (e) => {
    const newContact = { ...data };
    newContact[e.target.name] = e.target.value;

    newContact.categoryName !== "" && newContact.categorySlug !== ""
      ? setdisable(false)
      : setdisable(true);
    setData(newContact);
  };
  const handleImageDeleteFood = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);

    setSelectedImage(updatedImages);
  };
  const handleSeoMetaTitle = (e) => {
    const newMetaData = { ...data };
    newMetaData[e.target.name] = e.target.value;
    setData(newMetaData);
  };

  const handleSeoMetaDescription = (e) => {};

  const handleSeoMetaKeywords = (e) => {};

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedImage([...selectedImage, ...fileList]);
  };

  const handleMetaFileChange = (event) => {
    const metaFile = Array.from(event.target.files);
    setSelectedMetaImage(metaFile);
  };

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const submit = async () => {
    console.log("mgs", data);
    let subdata = {
      userID: getUserDetails()._id,
      blogTitle: data.title,
      blogSlug: data.title,
      blogDesc: EditorData,
      blogImage: [],
    };

    // await ImageUploader(Seller, "Diary/seo", selectedMetaImage)
    const formData = new FormData();

    formData.append("folder", "Diary/" + Seller?.userFullName);

    // var newSelectedImages = selectedImage;
    for (var i = 0; i < selectedImage.length; i++) {
      formData.append("cover", selectedImage[i]);
    }

    fetch(`${BaseURL}/imguploads`, {
      method: "POST",
      body: formData,
    })
      .then((data) => data.json())
      .then(async (response) => {
        console.log(response);
        // subdata.blogImage = response?.images[0];
        subdata.blogImage = response?.images;
        if (response.status === "Success") {
          console.log(response.status, subdata, "hhh");
          await axios
            .post(`${BaseURL}/create-food-diary`, subdata)
            .then((res) => {
              console.log(res.data);
              Swal.fire({
                icon: "success",
                title: "Food Diary created successfully!!!",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/my-diary");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };


       // Function to change the language
 const changeLanguage = (language) => {
  setCurrentLanguage(language);
};

  //Tab labels:
  const CreateDiary = getTranslation(
    "CreateDiary",
    currentLanguage,
    labels
  );
  const CreateDiaryTitle = getTranslation(
    "CreateDiaryTitle",
    currentLanguage,
    labels
  );
  const EnterYourTitle = getTranslation(
    "EnterYourTitle",
    currentLanguage,
    labels
  );
  const Uploaddiaryimages = getTranslation(
    "Uploaddiaryimages",
    currentLanguage,
    labels
  );
  const dragDrop = getTranslation(
    "dragDrop",
    currentLanguage,
    labels
  );
  const upload = getTranslation(
    "upload",
    currentLanguage,
    labels
  );
  const labelSave = getTranslation(
    "labelSave",
    currentLanguage,
    labels
  );


  return (
    <>
      <div class="row">
        <div class="col-xl-12 col-xxl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">{CreateDiary}</h4>
            </div>
            <div class="card-body custom-ekeditor">
              <div className="form-group mb-3">
                <label>{CreateDiaryTitle}</label>
                <input
                  type="text"
                  className="form-control input-default "
                  placeholder={EnterYourTitle}
                  label="Name:"
                  name="title"
                  value={data.title}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="formFile" className="form-label">
                  {Uploaddiaryimages}
                </label>
              </div>

              {selectedImage?.map((item, index) => {
                return (
                  <>
                    <img
                      className="image-box img-fluid"
                      alt="Diary Image"
                      width={"150px"}
                      height={"150px"}
                      src={URL.createObjectURL(item)}
                    />
                    <span
                      style={{
                        position: "relative",
                        top: "-35px",
                        left: "-26px",
                      }}
                    >
                      <div
                        className="btn btn-danger shadow btn-xs sharp"
                        onClick={() => handleImageDeleteFood(index)}
                      >
                        <i className="fa fa-trash"></i>
                      </div>
                    </span>
                  </>
                );
              })}
              <div className="mt-2 col-6">
                <div
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <input
                    name="readyFoodImage"
                    type="file"
                    id="input-file-upload"
                    multiple
                    onChange={handleFileChange}
                  />
                  <label id="label-file-upload" htmlFor="input-file-upload">
                    <div>
                      <p>{dragDrop}</p>
                      <span className="upload-button">{upload}</span>
                    </div>
                  </label>
                </div>
              </div>

              {/* <h2>Using CKEditor 5 build in React</h2> */}

              <div>
                <label>Description </label>
              </div>

              <CKEditor
                editor={ClassicEditor}
                config={{
                  removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"],
                }}
                //   data={form.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorData(data);
                  // setForm((prev) => ({ ...prev, description: data }));
                  // console.log({ event, editor, data });
                }}
              />
              {false && (
                <div className="row d-flex mt-3">
                  <div className="col-xl-2">
                    <div className="contact-title">
                      <h4 className="mb-xl-0 mb-2">SEO </h4>
                      <span>(Search Engine Optimization)</span>
                    </div>
                  </div>
                  <div className="col-xl-10">
                    <div className="setting-input mb-3">
                      <label className="form-label">Meta Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="metaTitle"
                        value={data.seoMetaTitle}
                        onChange={handleSeoMetaTitle}
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">Meta Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="metaDescription"
                        value={data.seoMetaDescription}
                        onChange={handleSeoMetaDescription}
                      />
                    </div>
                    <div className="setting-input mb-3">
                      <label className="form-label">Meta Keywords</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Keywords"
                        name="metaKeywords"
                        value={data.seoMetaKeywords}
                        onChange={handleSeoMetaKeywords}
                      />
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <div>
                          <label className="form-label">Meta Image</label>
                        </div>
                        {!selectedMetaImage && (
                          <input
                            type="file"
                            className="form-control input-default "
                            placeholder="Meta Image"
                            label="metaImage"
                            name="metaImage"
                            // value={data?.price}
                            onChange={handleMetaFileChange}
                          />
                        )}
                      </div>

                      <div className="form-group mb-3 col-md-6">
                        {selectedMetaImage && (
                          <div style={{ float: "left" }}>
                            {selectedMetaImage?.map((item, index) => {
                              return (
                                <>
                                  <img
                                    className="img-fluid"
                                    alt="Meta Image"
                                    width={""}
                                    src={URL.createObjectURL(item)}
                                  />
                                  <br />
                                  <Button
                                    style={{ marginLeft: 25 }}
                                    onClick={() => setSelectedMetaImage(null)}
                                  >
                                    Remove
                                  </Button>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Button
                className="mt-2"
                onClick={() => {
                  submit();
                }}
              >
                {labelSave}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FoodDairy;
