import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Card } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { LanguageContext } from "../../../context/LanguageContext";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/howDo.json";

function dateFormat(value) {
  let dateData = new Date(value);
  // Format the date as dd/mm/yyyy
  const formattedDate = dateData.toLocaleDateString("en-GB"); // 'en-GB' for the dd/mm/yyyy format
  return formattedDate;
}

const HowDoWeDoList = () => {
  const [category, setCategory] = useState();
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  useEffect(() => {
    //chackboxFun()

    axios
      .get(`${BaseURL}/get-how-do-we-do-by-user/${getUserDetails()._id}`)
      .then((res) => {
        console.log(res);
        setCategory(res.data.data[0].HowDoWeDoData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-how-do-we-do/${data}`)
      .then((res) => {
        console.log(res);
        // Remove the deleted item from the category state array
        setCategory((prevCategory) =>
          prevCategory.filter((item) => item._id !== data)
        );
        Swal.fire("Deleted!", "Your post has been deleted.", "success");
      })
      .catch((Err) => {
        console.log(Err);
        Swal.fire("Not Deleted!", "Something went wrong.", "failure");
      });
  };

  console.log(category);

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        //  Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        // Swal.fire("Something went wrong!");
      }
    });
  };


       // Function to change the language
 const changeLanguage = (language) => {
  setCurrentLanguage(language);
};

  //Tab labels:
  const Howdowedo = getTranslation(
    "Howdowedo",
    currentLanguage,
    labels
  );
  const MyHowdowedo = getTranslation(
    "MyHowdowedo",
    currentLanguage,
    labels
  );

  return (
    <>
      <PageTitle activeMenu={MyHowdowedo} motherMenu={Howdowedo} />
      <Card>
        <Card.Header>
          <Card.Title>{MyHowdowedo}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            {category === undefined ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th className="bg-none sorting_asc">
                      {/* <div className="form-check style-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="checkAll"
                          onClick={() => chackboxFun("all")}
                        />
                      </div> */}
                      ID
                    </th>
                    <th>Title</th>
                    {/* <th>Posted By</th> */}
                    <th>Before </th>
                    <th>After </th>
                    <th>Video</th>
                    <th>Date</th>
                    <th className="bg-none"></th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {category.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="application_sorting_1">
                          {/* <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div> */}
                          {item.id}
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <p>{item?.title}</p>
                          </div>
                        </td>
                        {/* <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <p>{item?.postedBy?.userFullName}</p>
                            <p>{item?.postedBy?.kitchenName}</p>
                          </div>
                        </td> */}
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={item.beforeImage[0].orginalImageUrl}
                              // src={item?.beforeImag[0]?.orginalImageUrl}
                              alt="images"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={item.afterImage[0].orginalImageUrl}
                              alt="images"
                            />
                          </div>
                        </td>
                        <td>
                          {item.VideoURL}
                          <div className="media-bx d-flex py-3  align-items-center">
                            <span className="badge badge-primary badge-pill ">
                              <Link
                                className="text-white"
                                to={item.videoURL}
                                target="_blank"
                              >
                                <i className="fa-solid fa-video"></i>
                              </Link>
                            </span>
                          </div>
                        </td>
                        <td>{dateFormat(item.createdDate)}</td>
                        <td>
                          <div>
                            <h4 className="text-primary">{item.role}</h4>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex gap-1">
                            <Link
                              to={`/edit-how-do-we-do/${item._id}`}
                              // state={item}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => {
                                del(item._id);
                              }}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default HowDoWeDoList;
