import { createBrowserRouter } from "react-router-dom";
import Main from "../jsx/layouts/Main";
import CreateFood from "../jsx/Seller/CreateFood/createfood";
import Home from "../jsx/components/Dashboard/Home";
import FoodList from "../jsx/Seller/CreateFood/foodlist";
import HowDoWeDo from "../jsx/Seller/Howdowedo/howdowedo";
import HowDoWeDoList from "../jsx/Seller/Howdowedo/howdowedoList";
import Login from "../jsx/Authentication/Login";
import PrivateRouter from "./PrivateRouter";
import CreateSubCategory from "../jsx/Seller/createSubCategory/createSubCategory";
import EditSubCategory from "../jsx/Seller/createSubCategory/editSubCategory";

import CreateCategory from "../jsx/Seller/category/category";
import Create_Recipe from "../jsx/Seller/create_recipe/create_recipe";
import Edit_Recipe from "../jsx/Seller/create_recipe/edit_recipe";
import Recipe_List from "../jsx/Seller/create_recipe/recipe_List";
import FoodDairy from "../jsx/Seller/postfoodDairy/foodDairy";
import FoodDairyList from "../jsx/Seller/postfoodDairy/listFoodDairy";
import BecomeSeller from "../jsx/Buyer/Application/BecomeSeller";
import BecomeRider from "../jsx/Buyer/Application/BecomeRider";
import DashboardDark from "../jsx/components/Dashboard/DashboardDark";
import SellerOrder from "../jsx/Seller/Order/SellerOrder";
import BuyerOrder from "../jsx/Seller/Order/BuyerOrder";
import CategoryList from "../jsx/Seller/category/categoryList";
import SubCategoryList from "../jsx/Seller/createSubCategory/subcategoryList";
import Profile from "../jsx/Profile/Profile";
import UpdateBankInfo from "../jsx/Seller/BankDetails/UpdateBankInfo";
import CreateCoupon from "../jsx/Seller/Coupon/coupon";
import EditFoodDairy from "../jsx/Seller/postfoodDairy/editFoodDairy";
import EditBasicInfo from "../jsx/Seller/EditBasicInfo/EditBasicInfo";
import UpdateFood from "../jsx/Seller/CreateFood/UpdateFood";
import CreateRecipeCategory from "../jsx/Seller/CreateRecipeCategory/CreateRecipeCategory";
import EditRecipeCategory from "../jsx/Seller/CreateRecipeCategory/EditRecipeCategory";
import RecipeCategoryList from "../jsx/Seller/CreateRecipeCategory/RecipeCategoryList";
import BecomeSellerProtectedRouter from "./BecomeSellerProtectedRouter";
import ForgetPassword from "../jsx/Authentication/ForgetPassword";
import OTP from "../jsx/Authentication/OTP";
import ResetPassword from "../jsx/Authentication/ResetPassword";
import LandingPage from "../jsx/Authentication/LandingPage";
import LandingPage2 from "../jsx/Authentication/LandingPage2";
import RiderOrder from "../jsx/Seller/Order/RiderOrder";
import HowDoWeDoEdit from "../jsx/Seller/Howdowedo/howdowedoEdit";
import OrderAssign from "../jsx/OrderAssign/OrderAssign";
import Transaction from "../jsx/Transaction/Transaction";
// import TransactionList from "../jsx/Transaction/TransactionList";
// import CreateWithdraw from "../jsx/withdraw/CreateWithdraw";
import CreateSellerWithdraw from "../jsx/withdraw/CreateSellerWithdraw";
import BecomeRiderProtectedRouter from "./BecomeRiderProtectedRouter";
import CreateRiderWithdraw from "../jsx/withdraw/CreateRiderWithdraw";

import TransactionListRider from "../jsx/Transaction/TransactionListRider";
import TransactionListSeller from "../jsx/Transaction/TransactionListSeller";
import BecomeRiderTest from "../jsx/Buyer/Application/BecomeRiderTest";
import WishOrder from "../jsx/Seller/wish-order/wishorder";
import WishFoodBuyer from "../jsx/Buyer/Food/WishFoodBuyer";
import WishFoodSeller from "../jsx/Seller/CreateFood/WishFoodSeller";
import CheckOut from "../jsx/Common/CheckOut/Checkout";
import Driver from "../jsx/components/Driver";
import TrackOrder from "../jsx/Common/TrackOrder/TrackOrder";
import PendingSellerOrder from "../jsx/Seller/Order/PendingSellerOrder";
import CouponList from "../jsx/Buyer/Coupon/CouponList";
import RejectedOrder from "../jsx/Seller/Order/RejectedSellerOrder";
import Review from "../jsx/Buyer/Reveiw/Review";

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRouter>
        <Main />
      </PrivateRouter>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/driver",
        element: <Driver />,
      },
      {
        path: "app-profile",
        element: <Profile />,
      },
      {
        path: "/order",
        element: <BuyerOrder />,
      },
      {
        path:"/order/:id",
        element:<TrackOrder/>
      },
      {
        path: "/seller-order",
        element: <SellerOrder />,
      },
      {
        path:"/seller-rejected-order",
        element:<RejectedOrder/>
      },
      {
        path:"/seller-pending-order",
        element:<PendingSellerOrder/>
      },
      {
        path:"/user-coupon",
        element:<CouponList/>
      }
,
      {
        path: "/rider-order",
        element: <RiderOrder />,
      },

      {
        path: "/create-food",
        element: <CreateFood />,
      },
      {
        path: "/my-food",
        element: <FoodList />,
      },
      {
        path: "/edit-food",
        element: <UpdateFood />,
      },
      {
        path: "/edit-food/:id",
        element: <UpdateFood />,
      },
      {
        path: "/create-how-do-we-do",
        element: <HowDoWeDo />,
      },
      {
        path: "/my-how-do-we-do",
        element: <HowDoWeDoList />,
      },
      {
        path: "/edit-how-do-we-do/:id",
        element: <HowDoWeDoEdit />,
      },

      {
        path: "/create-subCategory",
        element: <CreateSubCategory />,
      },
      {
        path: "/edit-my-subcategory/:id",
        element: <EditSubCategory />,
      },
      {
        path: "sub-category-list",
        element: <SubCategoryList />,
      },
      {
        path: "/create-category",
        element: <CreateCategory />,
      },
      {
        path: "category-list",
        element: <CategoryList />,
      },
      {
        path: "/create-recipe",
        element: <Create_Recipe />,
      },
      {
        path: "/edit-recipe/:id",
        element: <Edit_Recipe />,
      },
      {
        path: "/my-create-recipe",
        element: <Recipe_List />,
      },

      {
        path: "/create-diary",
        element: <FoodDairy />,
      },
      {
        path: "/my-diary",
        element: <FoodDairyList />,
      },

      {
        path: "/edit-diary",
        element: <EditFoodDairy />,
      },
      {
        path: "/edit-food-diary/:id",
        element: <EditFoodDairy />,
      },
      {
        path: "/become-seller",
        element: (
          <BecomeSellerProtectedRouter>
            {" "}
            <BecomeSeller />
          </BecomeSellerProtectedRouter>
        ),
      },
      {
        path: "/become-rider",
        element: (
          <BecomeRiderProtectedRouter>
            <BecomeRiderTest />
          </BecomeRiderProtectedRouter>
        ),
      },
      {
        path: "/become-rider2",
        element: <BecomeRider />,
      },
      {
        path: "/become-seller-next-step",
        element: <UpdateBankInfo />,
      },
      {
        path: "/coupon",
        element: <CreateCoupon />,
      },
      {
        path: "/edit",
        element: <EditBasicInfo />,
      },
      {
        path: "/create-recipe-category",
        element: <CreateRecipeCategory />,
      },
      {
        path: "/edit-recipe-category/:id",
        element: <EditRecipeCategory />,
      },
      {
        path: "/my-recipe-category",
        element: <RecipeCategoryList />,
      },
      {
        path: "/landing",

        element: <LandingPage />,
      },
      {
        path: "/landing2",
        element: <LandingPage2 />,
      },
      {
        path: "/edit-profile",
        element: <EditBasicInfo />,
      },
      {
        path: "/assign-order",
        element: <OrderAssign />,
      },
      {
        path: "/rider-transaction",
        element: <TransactionListRider />,
      },
      {
        path: "/seller-transaction",
        element: <TransactionListSeller />,
      },
      {
        path: "/create-rider-withdraw",
        element: <CreateRiderWithdraw />,
      },
      {
        path: "/create-seller-withdraw",
        element: <CreateSellerWithdraw />,
      },
      {
        path: "wish-order-buyer",
        element: <WishFoodBuyer />,
      },
      {
        path: "wish-order-seller",
        element: <WishFoodSeller />,
      },
      {
        path: "checkout/:id",
        element: <CheckOut />,
      },
      {
        path:"/review",
        element:<Review/>
      }
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/forget-password-otp",
    element: <OTP />,
  },
  {
    path: "/Reset-Password",
    element: <ResetPassword />,
  },
]);
export default routes;
